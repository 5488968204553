import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalService } from '../modal/service/modal.service';
import { BlockchainService } from '../../../../core/services/blockchain/blockchain.service';
import { ToastrService } from 'ngx-toastr';

export const CONNECT_WALLET_MODAL_ID = 'connect-wallet-moddal-id';

export enum ConnectWalletStep {
  INFO,
  SELECT_WALLET,
}

@Component({
  selector: 'app-connect-wallet-modal',
  standalone: true,
  imports: [ModalComponent],
  templateUrl: './connect-wallet-modal.component.html',
  styleUrl: './connect-wallet-modal.component.scss',
})
export class ConnectWalletModalComponent {
  @Input()
  public currentStep: ConnectWalletStep = ConnectWalletStep.INFO;

  @Output()
  public walletConectedEvent = new EventEmitter<void>();

  public connectWalletModalId = CONNECT_WALLET_MODAL_ID;

  public connectWalletStep = ConnectWalletStep;

  constructor(
    private modalService: ModalService,
    private blockchainService: BlockchainService,
    private toastr: ToastrService
  ) {}

  public closeModal(): void {
    this.modalService.close(this.connectWalletModalId);
  }

  public openModal(): void {
    this.currentStep = ConnectWalletStep.INFO;
    this.modalService.open(this.connectWalletModalId);
  }

  public async connectToProvider(): Promise<void> {
    try {
      await this.blockchainService.initializeConnection(true);
      this.walletConectedEvent.emit();

      this.closeModal();
      this.toastr.success('Metamask se conectó corectamente.');
    } catch (error) {
      this.toastr.error('No se pudo contectar. Intenta de nuevo.');
    }
  }
}
