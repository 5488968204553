import { Injectable } from '@angular/core';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import { BehaviorSubject } from 'rxjs';
import {
  POLYGON_MAINNET_CHAIN_ID,
  USDT_ABI,
  USDT_ADDRESS,
  UserWallet,
  WalletBasicInfo,
} from './blockchain.model';
import { AppStorageService } from '../app-storage/app-storage.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class BlockchainService {
  private provider: ethers.BrowserProvider | undefined;
  private signer: ethers.Signer | undefined;

  private _blockChainBasicInfo = new BehaviorSubject<UserWallet | undefined>(undefined);
  public blockChainBasicInfo$ = this._blockChainBasicInfo.asObservable();

  // Indica si el proveedor está conectado
  private isConnected = new BehaviorSubject<boolean>(false);
  public isConnected$ = this.isConnected.asObservable();

  constructor(
    private storageService: AppStorageService,
    private toastr: ToastrService
  ) {
    const walletBasicInfo = this.storageService.getWalletBasicInfo();

    if (walletBasicInfo) {
      this.initializeConnection(false);
    }
  }

  // Método asíncrono para inicializar la conexión
  public async initializeConnection(userTrigered?: boolean): Promise<void> {
    try {
      await this.connectToMetamaskProvider(userTrigered);
      this.addEventListeners();
    } catch (error) {
      console.error('No se logró iniciar la conexión con el proveedor:', error);
      this.isConnected.next(false);
      throw error;
    }
  }

  // Conectar al proveedor (MetaMask)
  private async connectToMetamaskProvider(userTrigered?: boolean): Promise<void> {
    const provider: any = await detectEthereumProvider();
    if (!provider) {
      throw new Error('MetaMask no está instalada o no fue detectada');
    }

    await provider.request({ method: 'eth_requestAccounts' });
    this.provider = new ethers.BrowserProvider(provider);
    this.signer = await this.provider.getSigner();

    await this.updateBasicInfoWithDefaults(userTrigered);
    this.isConnected.next(true);
  }

  public async updateBasicInfoWithDefaults(
    userTriggered?: boolean,
    walletBasicInfo?: WalletBasicInfo
  ): Promise<void> {
    let wallet = walletBasicInfo;
    if (!walletBasicInfo) {
      const walletId = await this.getUserAddress();
      const network = await this.getUserNetwork();
      wallet = { walletId, network };
    }

    this._blockChainBasicInfo.next({
      walletBasicInfo: wallet,
      userTriggered: userTriggered || false,
    });
    this.storageService.setWalletBasicInfo(wallet || null);
  }

  public async isProviderConnected(): Promise<boolean> {
    if (this.provider && this.signer) {
      return true;
    }
    try {
      await this.initializeConnection();
      return this.provider !== undefined && this.signer !== undefined;
    } catch (error) {
      return false;
    }
  }

  // Obtener la dirección del usuario (wallet)
  public async getUserAddress(): Promise<string> {
    if (!this.signer) {
      throw new Error('No se ha inicializado el firmante');
    }
    return this.signer.getAddress();
  }

  // Obtener la red en la que está el usuario
  public async getUserNetwork(): Promise<ethers.Network> {
    if (!this.provider) {
      throw new Error('No se ha inicializado el proveedor');
    }
    return this.provider.getNetwork();
  }

  // Obtener el balance del usuario
  public async getUserBalance(): Promise<number> {
    if (!this.provider) {
      throw new Error('No se ha inicializado el proveedor');
    }
    const userAddress = await this.getUserAddress();
    const balance = await this.provider.getBalance(userAddress);

    return Number(ethers.formatEther(balance.toString()));
  }

  // Obtener el balance de USDT del usuario
  public async getUserBalanceUSDT(): Promise<number> {
    if (!this.provider || !this.signer) {
      throw new Error('No se ha inicializado el proveedor o el firmante');
    }

    const userAddress = await this.getUserAddress();

    // Crear una instancia del contrato USDT
    const usdtContract = new ethers.Contract(USDT_ADDRESS, USDT_ABI, this.signer);

    // Obtener el balance del usuario
    const balance = await usdtContract['balanceOf'](userAddress);

    // USDT tiene 6 decimales, así que formateamos el valor correctamente
    const balanceFormatted = Number(ethers.formatUnits(balance, 6));

    return balanceFormatted;
  }

  // Función para cambiar la red a Polygon (Mainnet)
  public async switchToPolygonNetwork(): Promise<void> {
    const provider: any = await detectEthereumProvider();
    if (!provider) {
      throw new Error('MetaMask no está instalada o no fue detectada');
    }
    try {
      const currentNetwork = await this.getUserNetwork();
      if (currentNetwork.name.toLowerCase() === 'matic') {
        return;
      }
    } catch (error) {
      return;
    }

    try {
      // Intentamos cambiar a la red de Polygon
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: POLYGON_MAINNET_CHAIN_ID }],
      });
    } catch (error: any) {
      // Si MetaMask no tiene la red, la agregamos manualmente
      if (error.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: POLYGON_MAINNET_CHAIN_ID,
                chainName: 'Polygon Mainnet',
                rpcUrls: ['https://polygon-rpc.com/'], // URL RPC de Polygon Mainnet
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'POL',
                  decimals: 18,
                },
                blockExplorerUrls: ['https://polygonscan.com/'],
              },
            ],
          });
        } catch (addError) {
          console.error('Error al agregar la red de Polygon:', addError);
          throw new Error('No se pudo agregar la red de Polygon.');
        }
      } else {
        console.error('Error al cambiar a la red de Polygon:', error);
        throw new Error('No se pudo cambiar a la red de Polygon.');
      }
    }

    await this.connectToMetamaskProvider();
  }

  private addEventListeners(): void {
    if (this.provider) {
      try {
        (window as any).ethereum.on('chainChanged', (chainId: string) => {
          console.log('Red cambiada a:', chainId);

          // METAMASK recomienda recargar la página
          window.location.reload();
        });
        (window as any).ethereum.on('disconnect', (error: any) => {
          console.log('Desconectado de la red:', error);

          // METAMASK recomienda recargar la página
          window.location.reload();
        });

        (window as any).ethereum.on('accountsChanged', (accounts: any) => {
          console.log('La cuenta fue cambiada:', accounts);
          this.disconnect();

          // METAMASK recomienda recargar la página
          window.location.reload();
        });
      } catch (error) {
        console.error('Error agregando listeners al provider', error);
      }
    }
  }

  public disconnect(message?: string): void {
    this.provider = undefined;
    this.signer = undefined;
    this._blockChainBasicInfo.next(undefined);
    this.isConnected.next(false);
    this.storageService.setWalletBasicInfo(null);
    this.toastr.success(message || 'Se desconectó de Metamask');
  }

  // Determinar la moneda nativa según el chainId
  private getNativeCurrency(chainId: number): string {
    switch (chainId) {
      case 1:
        return 'ETH'; // Ethereum Mainnet
      case 137:
        return 'MATIC'; // Polygon Mainnet
      case 56:
        return 'BNB'; // Binance Smart Chain
      case 43114:
        return 'AVAX'; // Avalanche C-Chain
      default:
        return 'ETH'; // Por defecto, ETH
    }
  }
}
