import { ethers } from 'ethers';

export const USDT_ADDRESS = '0xc2132d05d31c914a87c6611c10748aeb04b58e8f';
export const USDT_ABI = ['function balanceOf(address owner) view returns (uint256)'];

export const POLYGON_MAINNET_CHAIN_ID = '0x89';
export const ETHERIUM_MAINNET_CHAIN_ID = '0x1';
export const TRON_TRC_20 = '0x2B66';
export const BASE_MAINNET_CHAIN_ID = '0x2105';

export interface UserWallet {
  walletBasicInfo: WalletBasicInfo | undefined;
  userTriggered: boolean | null | undefined;
}

export interface WalletBasicInfo {
  walletId: string;
  network: ethers.Network;
}
