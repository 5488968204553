<app-modal modalBodyClass="modal-xs" [id]="connectWalletModalId" [closeOnBgClick]="false">
  <div class="d-flex flex-column connect-wallet-container">
    <div class="d-flex justify-content-between connect-wallet-header">
      <span class="connect-wallet-title primary ps-1">Conectar wallet</span>
      <i class="bi bi-x-lg primary pointer pe-1" (click)="closeModal()"></i>
    </div>
    <div class="d-flex connect-wallet-body flex-fill">
      @if (currentStep === connectWalletStep.INFO) {
        <div
          class="connect-info-wallet-container d-flex flex-column align-items-center justify-content-center text-center"
        >
          <i class="bi bi-wallet primary body-icon"></i>
          <div>
            <span class="body-text-info"
              >Este será un proceso automático. Antes de continuar, por favor, asegúrate
              de tener vinculada correctamente tu wallet al explorador.</span
            >
          </div>
        </div>
      } @else {
        <div class="connect-wallet-provider-container d-flex flex-column flex-fill">
          <span class="wallet-provider-text">
            Escoge la wallet que quieres conectar a Pocket.
          </span>
          <div
            class="wallet-provider-item d-flex justify-content-between align-items-center w-100 pointer"
            (click)="connectToProvider()"
          >
            <div class="d-flex align-items-center">
              <img src="/assets/img/metamask-md.svg" />
              <span class="wallet-provider-item-name primary ms-3">Metamask</span>
            </div>
            <div>
              <img src="/assets/img/icons/arrow-right-icon.svg" />
            </div>
          </div>
        </div>
      }
    </div>
    @if (currentStep === connectWalletStep.INFO) {
      <div class="method-modal-footer w-100 text-end">
        <button
          class="btn btn-primary"
          type="submit"
          (click)="currentStep = connectWalletStep.SELECT_WALLET"
        >
          <span>conectar</span>
        </button>
      </div>
    }
  </div>
</app-modal>
